.report-detail {
    height: 600px;

    .report-section {
        border-bottom: 1px solid $colorBorder;
        padding-bottom: $distanceComponent;
        margin-bottom: $distanceComponent;

        &:last-child {
            border-bottom: unset;
            padding-bottom: unset;
            margin-bottom: unset;
        }

        .section-title {
            font-size: 16px;
            margin-bottom: $distanceComponent;
        }

        .section-item {
            display: flex;
            margin: 0 0 6px $distanceBlock;
            align-items: center;

            .label {
                min-width: 90px;
            }

            .value {
                width: 100%;
                padding-left: $distanceComponent;
            }
        }

        .section-button {
            //text-align: center;
            margin-top: $distanceBlock;
            margin-left: 126px;
        }

        .group-pattern {
            display: flex;
            padding: $distanceComponent;
            border: 1px dashed $colorBorder;

            .group-pattern-item {
                min-width: 120px;
                border-right: 1px solid $colorBorder;

                &:last-child {
                    border-right: unset;
                    padding-left: $distanceComponent;
                }
            }
        }
    }
}

