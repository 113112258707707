.card-control-status {
    overflow-y: auto;

    .ant-divider {
        margin: 12px 0;
    }

    .group-control {
        .group-item {
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;

            .label {
                display: inline-block;
                width: 50px;

                .ant-tag {
                    width: 40px;
                    text-align: center;
                }
            }

            .value {
                display: inline-block;

                span {
                    color: $colorGray;
                }
            }
        }

        .group-title {
            //font-size: 12px;
            margin-bottom: 6px;

            .title-main {
                @include hide-text-overflow;
                display: inline-block;
            }

            .title-extra {
                @include hide-text-overflow;
                display: inline-block;
                float: right;
                line-height: 20px;

                .group-item {
                    margin-bottom: 0;
                }
            }
        }

        .group-value {
            padding-left: $distanceComponent;
        }
    }
}

