.analysis-default {
    //min-height: calc(100% - 12px);
    height: 100%;

    .tab-title {
        font-size: $fontSizeTitle;
        @include hide-text-overflow();
    }

    .tab-group {
        margin-bottom: $distanceComponent;
    }

    .tab-content {
        //height: 500px;
        position: relative;

        .highcharts-react {
            height: 100%;
        }
    }
}
