.card-person-in-charge {
    height: 695px;
    overflow: auto;

    .group-person-in-charge {
        .group-title {
            font-weight: bold;
            display: flex;
        }

        .group-person {
            margin-left: 24px;

            .person-information {
                .value {
                    margin-left: 14px;
                }
            }
        }
    }
}