$zIndexHeader: 100;

$maxWidthContainer: 1200px;

$colorBlue: #0A6DD9;
$colorSuccess: #28a745;
$colorWhite: #fff;
$colorBlack: #000;
$colorDanger: #dc3545;
$colorWarning: #ffc107;
$colorWhiteOutFocus: rgba(255, 255, 255, .65);

$colorGray: #919191;
$colorBorder: #d9d9d9;

$colorPrimaryHighLight: #FFF7E5;
$colorPrimaryLight: #ffa940;
$colorPrimary: #fa8c16;
$colorPrimarySlow: #d46b08;

$fontSizeSmall: 12px;

$fontSizeDefault: 14px;
$lineHeightDefault: 18px;

$fontSizeMedium: 18px;
$lineHeightMedium: 20px;

$fontSizeTitle: 24px;
$lineHeightTitle: 32px;

$distanceItem: 8px;
$distanceComponent: 12px;
$distanceBlock: 24px;

$headerHeight: 64px;

$borderRadius: 3px;