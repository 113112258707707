.loading-main-wrapper {
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;

    .loading-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}