.col-power {
    display: flex;
    align-items: baseline;

    .text-value {
        margin-right: 18px;
        min-width: 40px;
        display: inline-block;
        text-align: right;
    }

    .ant-progress-success-bg, .ant-progress-bg {
        background: $colorPrimary;
    }
}
