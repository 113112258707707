@import url('./xy-theme.css');

#app {
    // width: 100vw;
    // height: 100vh;
    width: 100%; 
    height: 100vh;
}

.react-flow__panel.right {
    visibility: hidden;
}

.hidden-group {
    visibility: hidden;
}

.toggle-flow {
    pointer-events: none;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(20px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}

.infinite-animation {
  animation: fadeInOut 1s linear infinite;
}