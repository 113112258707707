.custom-collapse {
    position: relative;
    margin-bottom: 34px;

    .collapse-header {
        position: absolute;
        text-align: center;
        margin-bottom: 12px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 450px;

        .collapse-control-wrap {
            margin: 0;
        }

        .collapse-control {
            cursor: pointer;
            font-size: 15px;
        }
    }

    .collapse-content {
        position: relative;
        width: 100%;
        left: 0;
        top: 34px;
        opacity: 1;
        @include transition-fade-in()
    }

    .collapse-content.hide {
        position: absolute;
        opacity: 0;
        top: -50000px;
        left: -50000px;
        @include transition-fade-out()
    }
}

