.loading-wrapper {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;

    .loading-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loading-wrapper.overwrite {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
}