.report-list {
    .search-box {
        margin-bottom: $distanceComponent;
    }

    .search-group-result {
        overflow-y: auto;
        height: 485px;

        .group-result {
            .group-title {
                border-bottom: 1px solid rgb(219, 219, 219);
                padding: 10px 0 5px;
                font-size: 18px;
            }

            .list-data {
                .data-item {
                    button, a {
                        padding: 0 15px;
                        height: 24px;
                        line-height: 24px;
                        color: #525252;
                    }

                    &:first-child {
                        margin-top: $distanceItem;
                    }

                    &:hover {
                        background-color: $colorPrimaryHighLight;

                        button, a {
                            color: $colorPrimary;
                        }
                    }

                    transition: all linear 0.1s;
                }

                .data-item.default {
                    // todo
                }

                .data-item.selected {
                    background-color: $colorPrimaryHighLight;

                    button, a {
                        color: $colorPrimary;
                    }
                }
            }
        }
    }

}