.billing-schema {
    .billing-scheme-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $distanceBlock;

        .scheme-label {
            font-weight: bold;
            font-size: 18px;
        }
    }

    .billing-scheme-time-offset {
        margin-bottom: $distanceBlock;

        .ant-table.ant-table-small .ant-table-tbody > tr > td {
            //border-bottom: 0;
            border-color: $colorBorder;
        }
    }

    .billing-scheme-tariff {
        margin-left: 100px;
        margin-bottom: $distanceBlock;
    }
}