.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    //background: #d9edff;
    background: linear-gradient(to left, #fa8c16, #FDB777);

    .ant-form-item-control-input-content {
        text-align: left;
    }

    #login-form_username {
        height: 48px;
    }

    .login-form-button {
        width: 100%;
    }

    .login-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        max-width: 1000px;
        background-color: white;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
        overflow: hidden;
        margin: 0 auto;
        border-radius: 12px;

        .illustration-wrapper {
            display: flex;
            align-items: flex-end;
            max-width: 800px;
            min-height: 100%;
            background-color: #fffdf2;

            img {
                display: block;
                width: 100%;
                overflow: hidden;

                @media screen and (max-width: 1023px) {
                    display: none;
                }
            }
        }
    }

    #login-form {
        flex: 1 0 100%;
        max-width: 480px;
        width: 100%;
        padding: 60px;

        .login-title {

            .customLogo {
                height: 100px;
                margin-right: 16px;
                vertical-align: top;
            }

            .customInfo {
                font-size: 15px;
                text-align: center;
            }
        }

        p {
            margin-bottom: 30px;
        }

        p.form-title {
            color: #333333;
            font-size: 42px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 0;
        }

        .ant-form-item-label > label.ant-form-item-required::before {
            display: none;
        }

        .ant-input-affix-wrapper {
            border-radius: 10px;
            padding: 12px 15px;
        }

        .ant-btn {
            height: 42px;
            letter-spacing: 1px;
            border-radius: 6px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .login-box {
        flex-direction: column;
        box-shadow: none;
    }

    .illustration-wrapper {
        max-width: 100%;
        min-height: auto;
    }

    #login-form {
        max-width: 100%;
    }
}

@media screen and (width: 800px) and (min-width: 380px) {
    body {
        position: fixed;
    }

    #login-form {
        margin-bottom: 500px;

        .ant-input-affix-wrapper {
            width: 100%;
            align-items: center;

            .ant-input {
                font-size: 16px;
            }
        }

        button {
            width: 100%;
            font-size: 16px;
        }

        .form-option {
            text-align: left;
            font-size: 16px;
        }

        .login-title {
            .customInfo {
                font-size: 18px;
            }
        }

    }

    .login-page {
        width: 400px;
        height: 1000px;
    }
}

@media screen and (width: 375px) and (min-width: 250px) {
    body {
        position: fixed;
    }

    #login-form {
        margin-bottom: 500px;
        margin-left: 6px;
        margin-right: 6px;

        .ant-input-affix-wrapper {
            width: 90%;
            align-items: center;

            .ant-input {
                font-size: 16px;
            }
        }

        button {
            width: 90%;
            font-size: 16px;
        }

        .form-option {
            text-align: left;
            font-size: 16px;
        }

        .login-title {
            .customInfo {
                font-size: 18px;
            }
        }

    }

    .login-page {
        width: 400px;
        height: 1000px;
    }
}