.ticket-calendar {
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
        height: 70px;
    }

    .ticket-calendar-header {
        display: flex;
        justify-content: flex-end;
        padding: 12px 0;
    }

    .events {
        padding-left: 0;
        font-size: 8px;

        .ant-badge {
            font-size: 10px;

            .ant-badge-status-text {
                font-size: 10px;
            }
        }
    }
}
