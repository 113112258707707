.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
}

.form-custom {
    /*  .ant-form-item{
          margin-bottom: 15px;
      }

      .ant-form-item-with-help{
          margin-bottom: 0;
      }*/

    .update-histories {
        margin-top: 8px;

        .history-item {
            .ant-timeline-item-content {
                display: flex;

                .description {
                    width: 100%;
                    text-align: left;
                    font-style: italic;
                    padding: 0 $distanceComponent;
                }

                .update-at {
                    white-space: nowrap;
                }
            }
        }
    }

    .ant-timeline-item {
        padding-bottom: 12px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
