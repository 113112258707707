.site-description-item-profile-wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
    font-size: 12px;
    line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
    display: block;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    text-decoration: underline;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
    font-size: 14px;
    line-height: 1.66667;
}

.site-description-item-profile-p-label {
    display: inline-block;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.description-content {
    margin-left: $distanceBlock;
}
