.layout-main {
    height: 100%;
}

.app-content-wrapper {
    position: relative;

    .app-content {
        padding: 90px 12px 12px;
    }
}
