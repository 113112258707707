.auth {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    //background-position: center 110px;
    //background-size: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;

    .antd-pro-layouts-user-layout-content {
        padding: 32px 0 24px;

        .antd-pro-layouts-user-layout-top {
            text-align: center;

            .antd-pro-layouts-user-layout-header {
                height: 64px;
                line-height: 64px;

                .antd-pro-layouts-user-layout-logo {
                    height: 76px;
                    margin-right: 16px;
                    vertical-align: top;
                }

                .antd-pro-layouts-user-layout-title {
                    position: relative;
                    top: 2px;
                    color: rgba(0, 0, 0, .85);
                    font-weight: 600;
                    font-size: 33px;
                    font-family: Avenir, helvetica neue, Arial, Helvetica, sans-serif;
                }
            }

            .antd-pro-layouts-user-layout-desc {
                margin-top: 12px;
                margin-bottom: 40px;
                color: rgba(0, 0, 0, .45);
                font-size: 14px;
            }
        }
    }

    .antd-pro-pages-user-login-index-main {
        margin: 0 auto;
        width: 800px;
        min-height: 600px;
    }
}