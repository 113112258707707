/* xyflow theme files. Delete these to start from our base */

.react-flow {
    /* Custom Variables */
    --xy-theme-selected: #F57DBD;
    --xy-theme-hover: #C5C5C5;
    --xy-theme-edge-hover: black;
    --xy-theme-color-focus: #E8E8E8;

    /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
    --xy-node-border-default: 1px solid #EDEDED;

    --xy-node-boxshadow-default:
        0px 3.54px 4.55px 0px #00000005,
        0px 3.54px 4.55px 0px #0000000D,
        0px 0.51px 1.01px 0px #0000001A;

    --xy-node-border-radius-default: 8px;

    --xy-handle-background-color-default: black;
    --xy-handle-border-color-default: #AAAAAA;

    --xy-edge-label-color-default: #505050;
}

.react-flow__node-input,
.react-flow__node-default,
.react-flow__node-output,
.react-flow__node-group {
    padding: 5px !important;
    border-radius: 5px !important;
    width: 200px !important;
    height: 120px !important;
    color: var(--xy-node-color, var(--xy-node-color-default));
    text-align: center;
    border: none !important;
    background-color: var(--xy-node-background-color, var(--xy-node-background-color-default));
}

.react-flow.dark {
    --xy-node-boxshadow-default:
        0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05),
        0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13),
        0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);
    --xy-theme-color-focus: #535353;
}

.react-flow__node {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    border-radius: var(--xy-node-border-radius-default);
    background-color: var(--xy-node-background-color-default);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    flex-direction: column;
    border: var(--xy-node-border-default);
    color: var(--xy-node-color, var(--xy-node-color-default));
}

.react-flow__node.selectable:focus {
    box-shadow: 0px 0px 0px 4px var(--xy-theme-color-focus);
    border-color: #d9d9d9;
}

.react-flow__node.selectable:focus:active {
    box-shadow: var(--xy-node-boxshadow-default);
}


.react-flow__node.selectable:hover,
.react-flow__node.draggable:hover {
    border-color: var(--xy-theme-hover);
}

.react-flow__node.selectable.selected {
    border-color: var(--xy-theme-selected);
    box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__node-group {
    background-color: rgba(207, 182, 255, 0.4);
    border-color: #9E86ED;
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
    stroke: var(--xy-theme-edge-hover);
}

.react-flow__handle {
    background-color: var(--xy-handle-background-color-default);
}

.react-flow__handle.connectionindicator:hover {
    pointer-events: all;
    border-color: var(--xy-theme-edge-hover);
    background-color: white;
}

.react-flow__handle.connectionindicator:focus,
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
    border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
    border-radius: 0;
    border: none;
}

.react-flow__resize-control.handle {
    background-color: #ffffff;
    border-color: #9E86ED;
    border-radius: 0;
    width: 5px;
    height: 5px;
}

.react-flow__handle-top {
    visibility: hidden !important;
}

.react-flow__handle-right {
    visibility: hidden !important;
}

.react-flow__handle-left {
    visibility: hidden !important;
}

.react-flow__handle-bottom {
    visibility: hidden !important;
}