.rule-form {
    .btn-add-condition {
        padding-left: 158px;
    }

    .radio-item {
        width: 100px;
    }

    .other-email {
        font-style: italic;
        color: $colorDanger;
    }
}
