.input-group {
    padding: 9px;

    .input-group-item {
        margin-right: 12px;

        &:last-child {
            margin-right: unset;
        }
    }
}