.list-image {
    text-align: center;

    .ant-image-mask:hover {
        opacity: 0;
    }

    .slick-dots-bottom {
        bottom: -20px;
    }

    .slick-dots li button {
        padding: 2px;
        background: $colorPrimary;
    }

    .slick-dots li.slick-active button {
        padding: 2px;
        background: $colorPrimary;
    }

    .slick-dots li {
        margin: 0 6px;
    }
}
