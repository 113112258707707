.search-group {
    .search-input {
        display: flex;
        align-items: center;
        margin-bottom: $distanceComponent;

        .input-label {
            font-weight: bold;
            margin-right: 6px;
            min-width: 90px;
        }
    }
}

.action-group {
    text-align: right;
    margin-bottom: $distanceComponent;
}