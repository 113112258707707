.tag-tariff-status {
    border-radius: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.tag-tariff-status.section-1 {
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
}

.tag-tariff-status.section-2 {
    width: 50%;
    height: 100%;
    left: 50%;
    top: 0;
}