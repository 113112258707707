.group-gauges {
  .production-section {
    padding: 16px;
    padding-left: 10%;
    border-radius: 8px;
    margin-bottom: 16px;

    .value-main {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
      display: flex;
      align-items: baseline;
      gap: 4px;
    }

    .production-stats {
      margin-top: 16px;

      .stat-item {
        margin: 12px 0;

        .label {
          color: #666;
          font-size: 16px;
          margin-bottom: 4px;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
          display: flex;
          align-items: baseline;
          gap: 4px;
        }
      }
    }
  }

  // Fix vấn đề xuống dòng cho unit
  .wrap-value {
    display: flex;
    align-items: baseline;
    gap: 4px;
    flex-wrap: nowrap; // Thêm dòng này để ngăn xuống dòng
    padding-left: 10%;

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-shrink: 0;

      div {
        padding-left: 15%;
        white-space: nowrap;
      }
    }

    .unit {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      div {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .group-gauges {
    .production-section {
      .value-main {
        font-size: 16px;
      }

      .production-stats {
        .stat-item .value {
          font-size: 16px;
        }
      }
    }

    .wrap-value {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      .unit {
        padding-left: 0;
        flex-direction: row;
        gap: 4px;

        div {
          margin-top: 0 !important;
        }
      }
    }
  }
}

.gauge-column {
  font-size: 16px;
  padding-left: 10%;
}