// Here you put all global css rules.
@font-face {
    font-family: "OpenSans";
    src: local("OpenSans"), url("../fonts/OpenSans/OpenSans-Medium.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "OpenSans";
    src: local("OpenSans"), url("../fonts/OpenSans/OpenSans-MediumItalic.ttf");
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: local("OpenSans"), url("../fonts/OpenSans/OpenSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "OpenSans";
    src: local("OpenSans"), url("../fonts/OpenSans/OpenSans-BoldItalic.ttf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto/Medium.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto/MediumItalic.ttf");
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto/Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto/BoldItalic.ttf");
    font-weight: bold;
    font-style: italic;
}

body {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    min-width: 1024px;
    overflow-x: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans, sans-serif;
    font-family: Roboto, sans-serif;
}

.transform-180 {
    transform: rotate(180deg);
}

.transform-90 {
    transform: rotate(90deg);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}


.group-button {
    white-space: nowrap;

    button {
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.highcharts-react {
    width: 100%;
    position: relative;
    height: 350px;
}

.highcharts-react.heatmap {
    height: 400px;
}

.force-hide-chart-title {
    .highcharts-title {
        //display: none;
        opacity: 0;
    }
}

.page-title {
    font-size: 28px;
    line-height: 28px;
    margin: 16px 0 12px 0;
}

a {
    text-decoration: none;
    background-color: initial;

    &:hover {
        //text-decoration: none;
    }
}

.text-highlight {
    color: $colorPrimary;
}

.highlight {
    cursor: pointer;

    &:hover {
        color: $colorPrimaryLight;
    }

    transition: all linear 0.1s;
}

a.highlight {
    &:hover {
        text-decoration: underline;
    }
}

.text-success {
    color: $colorSuccess;
}

.text-warning {
    color: $colorWarning;
}

/* Begin Custom button */
.btn-success {
    color: #fff;
    border-color: $colorSuccess;
    background: $colorSuccess;

    &:hover {
        color: #fff;
        border-color: #1e7e34;
        background: #218838;
    }

    &:focus {
        color: #fff;
        border-color: $colorSuccess;
        background: $colorSuccess;
    }
}

.btn-success-ghost {
    color: $colorSuccess !important;
    border-color: $colorSuccess !important;

    &:hover {
        color: #1e7e34 !important;
        border-color: #1e7e34 !important;
    }

    &:focus {
        color: #1e7e34 !important;
        border-color: #1e7e34 !important;
    }
}

.btn-primary {
    color: #fff;
    border-color: $colorBlue;
    background: $colorBlue;

    &:hover {
        color: #fff;
        border-color: #0062cc;
        background: #0069d9;
    }

    &:focus {
        color: #fff;
        border-color: #0062cc;
        background: #0069d9;
    }
}

.btn-primary-ghost {
    color: $colorBlue !important;
    border-color: $colorBlue !important;

    &:hover {
        color: #0069d9 !important;
        border-color: #0062cc !important;
    }

    &:focus {
        color: #0069d9 !important;
        border-color: #0062cc !important;
    }
}

/* End Custom button */

/* Begin Custom tab */
.ant-tabs-tab {
    padding: 6px 10px;
}

/* End Custom tab */

/* Begin Custom table */
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 3px 5px;
    font-size: 13px;
}

.ant-table-pagination.ant-pagination {
    margin: 0;
    padding: 6px 10px;
}

/* End Custom table */

@keyframes attention {
    0% {
        text-shadow: 0 0 0 #fff;
    }
    15% {
        text-shadow: 0 0 2px #fff;
    }
    30% {
        text-shadow: 0 0 4px $colorPrimaryHighLight;
    }
    50% {
        text-shadow: 0 0 6px $colorPrimaryLight;
    }
    70% {
        text-shadow: 0 0 4px $colorPrimaryHighLight;
    }
    85% {
        text-shadow: 0 0 2px #fff;
    }
    100% {
        text-shadow: 0 0 0 #fff;
    }
}

// custom scrollbar
::-webkit-scrollbar {
    width: 8px;
    //border-radius: 10px;
}

::-webkit-scrollbar-track {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: whitesmoke;
    //border-radius: 10px;
}

::-webkit-scrollbar-thumb:vertical {
    background-color: whitesmoke;
    //border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: whitesmoke;
    //border-radius: 10px;
}